<template>
  <div class="chart-base" ref="chartRef"></div>
</template>

<script>
import { init as initChart } from 'echarts';

export default {
	props: {
		legend: {
			type: Array,
			default: () => [],
		},
		xAxis: {
			type: Array,
			default: () => [],
		},
		color: {
			type: Array,
			default: () => [],
		},
		series: {
			type: Array,
			default: () => [],
		},
		noGap: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		genChart() {
			const options = {
				legend: {
					bottom: 10,
					right: 60,
				},
				grid: {
					top: 20,
					left: 60,
					right: 60,
					bottom: 70,
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: { type: 'shadow' },
				},
				xAxis: {
					type: 'category',
					data: this.xAxis,
				},
				yAxis: {
					type: 'value',
				},
				series: this.series,
			};

			if (this.legend.length > 0) {
				options.legend.data = this.legend;
			}

			if (this.color.length > 0) {
				options.color = this.color;
			}

			if (this.noGap) {
				options.xAxis.boundaryGap = false;
			}

			initChart(this.$refs.chartRef).setOption(options);
		},
	},
	mounted() {
		this.genChart();
	},
};
</script>

<style lang="less" scoped>
.chart-base {
  height: 300px;
  width: 100%;
}
</style>

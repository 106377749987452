<template>
  <div class="section-panel">
    <div class="section-panel-header">
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
	props: {
		title: String,
	},
};
</script>

<style lang="less">
.section-panel {
  background-color: #fff;
  .section-panel-header {
    font-size: 16px;
    font-weight: 700;
    padding: 0 30px;
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    &::before {
      content: '';
      display: inline-block;
      height: 18px;
      width: 4px;
      margin-right: 10px;
      background-color: #41a6ee;
    }
  }
}
</style>
